<!--
* @Author: tianzl
* @Date: 2023-10-19 09:41:25
* @LastEditors: tianzl
* @LastEditTime: 2023-10-19 09:41:25
* @Description: 线索培育-编辑页面
 -->
 <template>
  <div class="edit-clue">
    <van-nav-bar
      v-if="!$isMobile && ['group_chat_tools', 'single_chat_tools'].includes($route.query.contextEntry)"
      fixed
      placeholder
      :title="$route.meta.title"
      left-text="返回"
      left-arrow
      z-index="1000"
      @click-left="$router.back()"
    />
    <van-form ref="editForm" input-align="right" error-message-align="right" :label-width="labelWidth" :readonly="!isEdit"  @submit.native.prevent>
      <van-collapse v-model="activeNames" style="padding: 16px;">
        <van-collapse-item title-class="font-bold" class="form-collapse-item" name="1">
          <template #title>
            <div class="ownTitle">
              <span>基本信息</span>
              <img v-if="operate == 'view' && !isEdit && (userInfo.authorizeIds.includes(formData.followUserId) || userInfo.id == formData.authorizedEmployeeId)" class="img" :src="editIcon" width="18px" @click.stop="isEdit = true"/>
            </div>
          </template>
          <!-- 客户姓名 -->
          <van-field
            v-model.trim="formData.userName"
            required
            label="客户姓名"
            :placeholder="isEdit ? '请输入客户姓名' : '暂无'"
            maxlength="20"
            :rules="[{required: true, trigger: 'onBlur'}]"
          />
          <!-- 联系电话 -->
          <van-field
            v-model.trim="formData.userMobile"
            required
            :disabled="mobileDisabled"
            :type="mobileDisabled?'text':'number'"
            label="联系电话"
            :placeholder="isEdit ? '请输入联系电话' : '暂无'"
            :required="userMobileCheck.some((item) => item.required)"
            :rules="userMobileCheck"
            :formatter="(value)=> value.replace(/\s+/g, '')"
            @change="chengeMobile"
          />
          <!-- 微信 -->
          <van-field
            v-if="!isEdit"
            label-class="van-field-label"
            label="客户微信"
            :placeholder="isEdit ? '请选择一个微信好友' : '暂无'"
            :value="formData.wechatInfo?.name"
            readonly>
            <template #button>
              <div class="wechatFriend" >
                <wxIcon  v-if="isEdit && !(formData.wechatInfo?.externalUserid)" :isShowImg="false" name="添加"/>
                <externalUserIcon v-if="isEdit" :isShowImg="false" name="绑定" @selectUser="selectUser"/>
              </div>
            </template>
          </van-field>
          <FormVue :isEdit="isEdit" :label-width="labelWidth" :list="info" :formData="formData"/>          
        </van-collapse-item>
        <van-collapse-item title-class="font-bold" class="form-collapse-item" title="购车信息" name="2">
          <FormVue :isEdit="isEdit" :label-width="labelWidth" :list="buyCarInfo" :formData="formData"/>
        </van-collapse-item>
        <van-collapse-item v-if="!isEdit" title-class="font-bold" class="form-collapse-item" title="其他信息" name="3">
          <FormVue :isEdit="isEdit" :label-width="labelWidth" :list="elseInfo" :formData="formData"/>
        </van-collapse-item>
      </van-collapse>
      <div v-if="isEdit" class="btns">
        <van-button class="btn" round @click.stop="goBack">返回</van-button>
        <van-button class="btn" round  type="primary" @click="onSubmit">保存</van-button>
      </div>
    </van-form>
  </div>
</template>
<script>
import editIcon from '@/images/edit.png'
import clueServices from '@/services/clueServices'
import { validate } from '@/utils/validate'
import externalUserIcon from '@/components/card-view/external-user-icon.vue'
import wxIcon from '@/components/card-view/wx-icon.vue'
import FormVue from '@/modules/components/form/index.vue'
import AddressSystem from '@/components/addressSystem.vue'
import selectDate from '@/components/date-picker'
import commonSelector from '@/components/common-selector'
import selectCar from '@/components/select-car'
import store from '@/store'
import { mapGetters } from 'vuex'
import { genderList,customerTypeList,bigCustomList,isReplaceLIst,isEvaluateLIst,isChargeList,isParkingSpaceList,minDate,maxDate } from '@/modules/clue/config.js'
import { dateFormat,getArrToOBj } from '@/utils'
export default {
  name:'EditClue',
  components:{ externalUserIcon,AddressSystem,FormVue,wxIcon },
  data(){
    const that = this
    return {
      editIcon,
      operate: '',
      mobileDisabled: false,
      formData: {
        userMobile: '',
        wechatInfo: {}, // 微信信息
        userName: '',
        customerSource: {}, // 来源渠道
        userSex: '',
        userType: '',
        companyName: '',
        detail:{},
        isMajorCustomer:'',
        userAddress:'',
        otherPhone:'',
        otherPhone2:'',
        planDriveTime:'',
        planBuyTime:'',
        payType:'',
        partnerTry:'',
        rival:'',
        isReplace:'',
        replaceCar:'',
        isEvaluate:'',
        isCharge:'',
        isParkingSpace:'',
        chargeAddress:'',
        useType:'',
        modelCode:'',
        modelName:'',
        seriesCode:'',
        seriesName: '',
        userCity:'',
        userCityCode:'',
        userCounty:'',
        userCountyCode:'',
        userProvince:'',
        userProvinceCode:'',
        chargeCity:'',
        chargeCityCode:'',
        chargeCounty:'',
        chargeCountyCode:'',
        chargeProvince:'',
        chargeProvinceCode:'',
        otherPhone1:'',
        otherPhone2:'',
        onlineName:'',
        channelOne:'',
        channelTwo:'',
        channelThree: '', 
        channelFour: '',
        channelFive: '',
        createTime:'',
        state:'',
        hatchUserName:'',
        lastFollowUserName:'',
        followUserName:'',
        followUserRoleCode:''
      },
      activeNames: ['1', '2','3'],
      id:'',
      genderList,customerTypeList,bigCustomList,
      buyCarInfo:[
        {
          label:'意向车型',
          field:'',
          get value() {
            const { modelName='', seriesName= '' } = that.formData
            const arr = []
            seriesName && arr.push(seriesName)
            modelName && arr.push(modelName)
            return arr.join(' ')
          },
          type:'select',
          click:(key,item)=>{
            // 选择意向车型
            const { modelCode='', modelName='', seriesCode='', seriesName= '' } = that.formData
            selectCar({ initCarInfo:{ modelCode, modelName, seriesCode, seriesName } })
              .then(carInfo => {
                if (typeof carInfo.modelCode === 'undefined') {
                  this.$toast('请选择正确的车系车型')
                  return
                }
                Object.assign(that.formData,carInfo)
                this.$refs.carInfo.validate()
              })
              .catch(() => { })
          },
          get isHidden() {
            return that.operate === 'create'
          }
        },
        // {
        //   label:'预计试驾时间',
        //   field:'planDriveTime',
        //   type:'date',
        //   click:that.selectDate,
        //   get value() {
        //     return dateFormat(that.formData.planDriveTime,'YYYY-MM-DD')
        //   },
        //   get isHidden() {
        //     return !(that.operate === 'edit')
        //   }
        // },
        // {
        //   label:'试驾意向等级',
        //   field:'planDriveTime',
        //   type:'text',
        //   get value() {            
        //     const { tryDriveLevel:code,planDriveTime } = that.formData
        //     const obj = that.$store.getters.getDictHash(1012).find(item=>{
        //       return item.code === code
        //     })
        //     return `${ obj?.name || ''} (${dateFormat(planDriveTime,'YYYY-MM-DD')})`     
        //   },
        //   get isHidden() {
        //     return that.isEdit
        //   }
        // },
        // {
        //   label:'购车意向等级',
        //   field:'leadLevel',
        //   type:'text',
        //   get value() { 
        //     const { leadLevel } = that.formData
        //     return leadLevel ? `${leadLevel}级`:''
        //   },
        //   get isHidden() {
        //     return that.isEdit
        //   }
        // },
        // {
        //   label:'购车时间',
        //   field:'planBuyTime',
        //   type:'date',
        //   click:that.selectDate,
        //   get value() {
        //     return dateFormat(that.formData.planBuyTime,'YYYY-MM-DD')
        //   },
        //   get isHidden() {
        //     return that.operate === 'create'
        //   }
        // },
        // {
        //   label:'付款方式',
        //   field:'payType',
        //   type:'tags',
        //   get options() {
        //     return that.$store.getters.getDictHash(1001) || []
        //   },
        //   keys:{
        //     name: 'name',
        //     id:'code'
        //   },
        //   get isHidden() {
        //     return that.operate === 'create'
        //   }
        // },
        // {
        //   label:'共同决策人是否试驾',
        //   field:'partnerTry',
        //   get value() {
        //     const { formData,getPartnerTry } = that
        //     return getPartnerTry[formData.partnerTry]?.name || ''
        //   },
        //   type:'select',
        //   click:(key,item)=>{
        //     that.selectField(key,'1002')
        //   },
        //   get isHidden() {
        //     return that.operate === 'create'
        //   }
        // },
        {
          label:'关注竞品',
          field:'rival',
          type:'textarea',
          maxlength: '50',
          get isHidden() {
            return that.operate === 'create'
          }
        },
        {
          label:'是否置换',
          field:'isReplace',
          type:'tags',
          options:isReplaceLIst,
        },
        {
          label:'置换车辆',
          field:'replaceCar',
          type:'textarea',
          maxlength: '50',
          get isHidden() {
            return !that.formData.isReplace
          },
        },
        {
          label:'是否估价',
          field:'isEvaluate',
          type:'tags',
          options:isEvaluateLIst,
          get isHidden() {
            return !that.formData.isReplace
          },
        },
        {
          label:'是否需要安装充电桩',
          field:'isCharge',
          type:'tags',
          options:isChargeList,
        },{
          label:'是否有固定车位',
          field:'isParkingSpace',
          type:'tags',
          options:isParkingSpaceList,
        },
        {
          label:'充电桩安装地址',
          field:'',
          type:'render',
          get isHidden() {
            return !that.formData.isCharge
          },
          get value() {
            const { chargeCity,chargeProvince,chargeCounty } = that.formData
            const arr = []
            chargeProvince && arr.push(chargeProvince)
            chargeCity && arr.push(chargeCity)
            chargeCounty && arr.push(chargeCounty)
            return arr.join('/')
          },
          renderFn(h,item){
            const keys = {
              cityCode:'chargeCityCode',
              city:'chargeCity',
              provinceCode:'chargeProvinceCode',
              province:'chargeProvince',
              countyCode:'chargeCountyCode',
              county:'chargeCounty',
            }
            return <AddressSystem isDisabled={!that.isEdit} formData={that.formData} keys={keys} label="充电桩安装地址" inputAlign="right" labelWidth='7.2em' on-change={(val)=>{ that.formData = val }}/>
          }
        },
        {
          label:'详细地址',
          field:'chargeAddress',
          type:'input',
          maxlength:'50',
          get isHidden() {
            return !that.formData.isCharge
          },
        },
        {
          label:'车辆用途',
          field:'useType',
          type:'select',
          get value() {
            const { formData,getUseType } = that
            return getUseType[formData.useType]?.name || ''
          },
          click:(key,item)=>{
            that.selectField(key,'1003')
          }
        },
      ],
      info:[
        {
          label:'客户性别',
          field:'userSex',
          type:'tags',
          options:genderList,
        },
        {
          label:'客户类型',
          field:'userType',
          type:'tags',
          options:customerTypeList,
        },
        {
          label:'公司名称',
          field:'companyName',
          type:'textarea',
          maxlength:'50',
          get isHidden() {
            return that.formData.userType===1
          }
        },
        {
          label:'是否大客户',
          field:'isMajorCustomer',
          type:'tags',
          options:bigCustomList,
        },
        {
          label:'常住地区',
          field:'',
          type:'render',
          renderFn(h,item){
            return <AddressSystem isDisabled={!that.isEdit} formData={that.formData} label="常住地区" inputAlign="right" labelWidth='7.2em' on-change={(val)=>{ that.formData = val }}/>
          },
          get value() {
            const { userCity,userProvince,userCounty } = that.formData
            const arr = []
            userProvince && arr.push(userProvince)
            userCity && arr.push(userCity)
            userCounty && arr.push(userCounty)
            return arr.join('/')
          },
        },
        {
          label:'详细地址',
          field:'userAddress',
          type:'textarea',
          maxlength:'50',
        },
        {
          label:'其他联系方式1',
          field:'otherPhone1',
          type:'number',
          get rules() {
            return that.otherPhoneCheck('otherPhone1')
          }
        },
        {
          label:'其他联系方式2',
          field:'otherPhone2',
          type:'number',
          get rules() {
            return that.otherPhoneCheck('otherPhone2')
          }
        },
      ],
      elseInfo:[
        {
          label:'销售门店',
          field:'dealerName',
          type:'text',
        },
        {
          label:'来源渠道',
          field:'onlineName',
          type:'textarea',
          get value() {
            const { onlineName, channelOne, channelTwo, channelThree, channelFour, channelFive } = that.formData
            const arr = []
            onlineName && arr.push(onlineName)
            channelOne && arr.push(channelOne)
            channelTwo && arr.push(channelTwo)
            channelThree && arr.push(channelThree)
            channelFour && arr.push(channelFour)
            channelFive && arr.push(channelFive)
            return arr.join('/')
          }
        },
        {
          label:'线索创建时间',
          field:'createTime',
          type:'text',
        },
        {
          label:'客户留资时间',
          field:'createTime1',
          type:'text',
          get value() {
            const { createTime } = that.formData
            return createTime
          }
        },
        {
          label:'培育状态',
          field:'hatchState',
          type:'text',
          get value() {
            const { hatchState } = that.formData
            const obj = getArrToOBj(that.$store.getters.getDictHash(1000) || [])
            return obj[hatchState]?.name || '暂无'
          }
        },
        {
          label:'培育人员',
          field:'hatchUserName',
          type:'text',
        },
        {
          label:'DCC专员',
          field:'lastFollowUserName',
          type:'text',
          get value() {
            const { isDccRoleCode,formData } = that
            return isDccRoleCode ? (formData.followUserName || '暂无') : (formData.lastFollowUserName || '暂无')
          }
        },
        {
          label:'销售顾问',
          field:'followUserName',
          type:'text',
          get value() {
            const { isConsultantCode,formData } = that
            return isConsultantCode ? formData.followUserName : '暂无'
          }
        },
      ],
      isEdit: true,
      labelWidth:'9.2em',
    }
  },
  computed:{
    ...mapGetters(['templateClueInfo','userInfo']),
    // 客户手机号验证
    userMobileCheck() {
      // 跟进方式-展厅接待可不填手机号
      const { userMobile, wechatInfo } = this.formData
      const rules = []
      // 如果有手机号则验证
      if (userMobile && !this.mobileDisabled) {
        rules.push(validate.mustPhone)
      }
      if (!(wechatInfo?.externalUserid) && !this.mobileDisabled) {
        rules.push({ required: true, trigger: 'onBlur' })
      }
      return rules
    },
    payTypeList() {
      return this.$store.getters.getDictHash(1001) || []
    },
    getPartnerTry() {
      return getArrToOBj(this.$store.getters.getDictHash(1002) || [])
    },
    getUseType() {
      return getArrToOBj(this.$store.getters.getDictHash(1003) || [])
    },
    isDccRoleCode() {
      const { followUserRoleCode } = this.formData
      // const obj = getArrToOBj(this.$store.getters.getDictHash(1014) || [])
      return followUserRoleCode === '1014001'
    },
    isConsultantCode() {
      const { followUserRoleCode } = this.formData
      return followUserRoleCode === '1014002'
    }
  },
  created(){
    // 获取线索详情
    const { id, operate, templateClueInfo } = this.$route.query
    this.id = id
    this.operate = operate
    this.isEdit = ['edit', 'create'].includes(operate)
    if (id){
      this.getClueDetail()
    }
    if (!id && operate === 'create' && templateClueInfo) {
      Object.assign(this.formData, JSON.parse(templateClueInfo), this.templateClueInfo)
      if (this.formData.userMobile && /^1[3456789]\d{9}$/.test(this.formData.userMobile)) this.mobileDisabled = true
    }
  },
  methods:{
    otherPhoneCheck(type) {
      const rules = []
      if (this.formData[type]) {
        rules.push(validate.mustPhone)
      }
      return rules
    },
    selectField(field, dictType, multiple = false) {
      const _obj = {
        field,
        dictType,
        multiple,
        // excludeCode: field === 'isDriveOption' ? ['1006001', '1006002', '1006003'] : [],
        isDisableTips: '不支持修改',
        // lastSelect: this.leadLevelInfo[field]
      }
      commonSelector(_obj).then(res => {
        this.formData[field] = res.dictCode
      })
    },
    // 选择时间
    selectDate(field, title) {
      selectDate({ type: 'date' , minDate, maxDate })
        .then((res) => {
          this.formData[field] = dateFormat(res,'YYYY-MM-DD') + (res?' 23:59:59':'')
        })
    },
    selectUser(crmCustomer) {
      this.formData.wechatInfo = crmCustomer
      if (!this.formData.userMobile) this.baseClueInfoFilled('wx')
    },
    // 取消
    goBack() {
      this.$router.go(-1)
    },
    onSubmit() {
      this.$refs.editForm.validate().then(res => {
        const { detail, wechatInfo, ...query } = this.formData
        if (this.operate === 'edit' || (this.operate === 'view' && this.isEdit)) {
          clueServices.updateClueDetail({ 
            ...query,
            userWeChatName: wechatInfo.name,
            userWeChat: wechatInfo.externalUserid, }).then(res=>{
            this.$toast('保存成功')
            setTimeout(()=>{
              this.goBack()
            }, 1000)
          })
        } else {
          store.commit('app/setTemplateInfo', { ...query, wechatInfo })
          this.goBack()
        }
      })
    },
    chengeMobile() {
      if (/^1[3456789]\d{9}$/.test(this.formData.userMobile)){
        this.baseClueInfoFilled('tel')
      }
    },
    // 通过电话号码判重
    async baseClueInfoFilled(type) {
      // if (this.judgeExists) return false
      // this.judgeExists = true
      try {
        // loading.showLoading('线索验证中')
        const { wechatInfo: { externalUserid:userWechat }, userMobile } = this.formData
        const record = await clueServices.clueExist({ userWechat, userMobile, dealerId: this.userInfo.dealers[0]?.id })
        // this.judgeExists = false
        // loading.hideLoading()
        this.handleLink(record, type)
      } catch (error) {
        // loading.hideLoading()
        // this.judgeExists = false
        this.$toast.fail(error.message)
      }
    },
    // 去跟进
    goFollow(id) {
      this.$router.push({
        path: '/clue-follow',
        query: {
          id,
        }
      })
    },
    // 校验结果处理
    handleLink(data, type) {
      if (!data.id) return false
      // 有线索 但无跟进人
      if (!data.followUserId) {
        this.$dialog.confirm({
          title: '提示',
          message: '该线索还未分配跟进人，是否领取 ？',
          confirmButtonColor: '#B9921A',
          confirmButtonText: '领取',
        }).then(async() => {
          const result = await clueServices.receiveClue({ id: data.id })
          if (result) {
            this.$toast('领取成功')
            setTimeout(() => {
              this.goFollow(data.id)
            }, 1000)
          }
        })
      }
      // 有线索 跟进人是自己
      if (data.followUserId && data.followUserId === this.userInfo.id) {
        this.$dialog.confirm({
          title: '提示',
          message: '您正在跟进该客户，无需重复创建，是否去填写跟进记录？',
          confirmButtonColor: '#B9921A',
          confirmButtonText: '去填写',
        }).then(() => {
          this.goFollow(data.id)
        }).finally(data => {
          type === 'tel' ? this.formData.userMobile = '' : this.formData.wechatInfo = {}
        })
      }
      // 有线索 跟进人是他人
      if (data.followUserId && data.followUserId !== this.userInfo.id) {
        this.$dialog.confirm({
          title: '提示',
          message: `【${data.followUserName}】正在跟进该客户，不允许重复创建，您是否去填写跟进记录？`,
          confirmButtonColor: '#B9921A',
          confirmButtonText: '去填写',
        }).then(() => {
          // this.goFollow(data.id)
          this.$router.push({
            path: '/clue-remark',
            query: {
              id:data.id,
            }
          })
        }).finally(data => {
          type === 'tel' ? this.formData.userMobile = '' : this.formData.wechatInfo = {}
        })
      }
    },
    async getClueDetail(){      
      const res = await clueServices.getHatchClueDetail({ id: this.id })
      // this.mobileDisabled = res.userMobile ? /^1[3456789]\d{9}$/.test(res.userMobile) : false
      this.mobileDisabled = !!res.userMobile
      const { 
        isMajorCustomer='', 
        userAddress='',
        otherPhone1='',
        otherPhone2='',
        planDriveTime='',
        planBuyTime='',
        payType='',
        partnerTry='',
        rival='',
        isReplace='',
        replaceCar='',
        isEvaluate='',
        isCharge='',
        isParkingSpace='',
        chargeAddress='',
        useType='',
        userCity='',
        userCityCode='',
        userCounty='',
        userCountyCode='',
        userProvince='',
        userProvinceCode='',
        chargeCity='',
        chargeCityCode='',
        chargeCounty='',
        chargeCountyCode='',
        chargeProvince='',
        chargeProvinceCode='',
        companyName='',
      } = res?.detail || {}
      const {
        userWeChat='',
        userWeChatName='',
        userMobile = ''
      } = res
      Object.assign(this.formData,res,{
        isMajorCustomer,
        userAddress,
        otherPhone1,
        otherPhone2,
        planDriveTime,
        planBuyTime,
        payType,
        partnerTry,
        rival,
        isReplace,
        replaceCar,
        isEvaluate,
        isCharge,
        isParkingSpace,
        chargeAddress,
        useType,
        userCity,
        userCityCode,
        userCounty,
        userCountyCode,
        userProvince,
        userProvinceCode,
        chargeCity,
        chargeCityCode,
        chargeCounty,
        chargeCountyCode,
        chargeProvince,
        chargeProvinceCode,
        companyName,
        wechatInfo: {
          externalUserid: userWeChat,
          name: userWeChatName
        },
        userMobile,
      })
      console.log('res:',this.formData,res)
    }
  }
}
</script>

 <style lang="less" scoped>
 .edit-clue{
    padding-bottom: 80px;
    /deep/ .van-nav-bar--fixed {
      left: 50%;
      max-width: 500px;
      transform: translateX(-50%);
    }
   /deep/.van-collapse-item__content {
     padding: 12px 0px;
     .van-cell__title{
       span{
         color: @black;
       }
     }
   }
   /deep/ .font-bold {
     font-weight: 600;
     .ownTitle{
       display: flex;
       align-items: center;
       img{
         margin-left: 4px;
         cursor: pointer;
       }
     }
   }
 }
 .wechatFriend{
   display: flex;
   /deep/ .name{
     color: #B9921A;
     background: rgba(238, 212, 132, 0.45);
     padding: 4px 8px;
     line-height: 1;
     border-radius: 4px;
   }
 }
 .btns{
   margin-top:20px;
   display: flex;
   justify-content: space-around;
   position: fixed;
   bottom:0;
   left: 50%;
   transform: translate(-50%);
   width: 100%;
   max-width: 500px;
   background-color: #fff;
   padding: 20px 0;
   .btn{
     width: 168px;
   }
 }
 </style>
 